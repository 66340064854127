import React from 'react';

const IconCpp = ({ ...styles }) => (
  <svg viewBox="0 0 64 64" version="1.1" {...styles}>
    <g id="logos/c-copy-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fill="#C5E4F2" x="0" y="0" width="64" height="64"></rect>
      <path
        d="M39.1935203,38 L39.1935203,34.0186567 L42.664,34.0186567 L42.664,30.8787313 L39.1935203,30.8787313 L39.1935203,27 L35.1344797,27 L35.1344797,30.8787313 L31.664,30.8787313 L31.664,34.0186567 L35.1344797,34.0186567 L35.1344797,38 L39.1935203,38 Z M52.1935203,38 L52.1935203,34.0186567 L55.664,34.0186567 L55.664,30.8787313 L52.1935203,30.8787313 L52.1935203,27 L48.1344797,27 L48.1344797,30.8787313 L44.664,30.8787313 L44.664,34.0186567 L48.1344797,34.0186567 L48.1344797,38 L52.1935203,38 Z"
        id="++"
        fill="#1A567E"
        fillRule="nonzero"
      ></path>
      <path
        d="M20.5825545,45 C25.6199377,45 29.0809969,42.0841121 31,38.682243 L26.2367601,36.2176235 C25.4143302,37.6408545 24.1464174,39.8624833 20.9937695,39.8624833 C17.7383178,39.8624833 15.0311526,37.1895861 15.0311526,31.8090788 C15.0311526,27.7129506 17.0872274,24.0333778 20.9937695,24.0333778 C23.4610592,24.0333778 25.6199377,25.9425901 26.1339564,27.7823765 L30.9314642,25.35247 C29.2180685,21.6728972 25.1744548,19 20.7196262,19 C13.8660436,19 9,24.7276368 9,31.9826435 C9,40.2790387 14.1744548,45 20.5825545,45 Z"
        id="C"
        fill="#1A567E"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default IconCpp;
