import React, { useId } from 'react';

const IconKotlin = ({ ...styles }) => {
  const hash = useId();

  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
      <g clip-path="url(#clip0_823_415)">
        <path d="M0 28.8094L14.056 14.7534L28 28.8094H0Z" fill={`url(#${hash}paint0)`} />
        <path d="M0 0.809326H14.056L0 15.9853V0.809326Z" fill={`url(#${hash}paint1)`} />
        <path d="M14.056 0.809326L0 15.5933V28.8093L14.056 14.7533L28 0.809326H14.056Z" fill={`url(#${hash}paint2)`} />
      </g>
      <defs>
        <linearGradient
          id={`${hash}paint0`}
          x1="7.45388"
          y1="35.3556"
          x2="20.6826"
          y2="22.1268"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09677" stop-color="#0095D5" />
          <stop offset="0.3007" stop-color="#238AD9" />
          <stop offset="0.6211" stop-color="#557BDE" />
          <stop offset="0.8643" stop-color="#7472E2" />
          <stop offset="1" stop-color="#806EE3" />
        </linearGradient>
        <linearGradient
          id={`${hash}paint1`}
          x1="1.96006"
          y1="6.43262"
          x2="9.64331"
          y2="-1.25063"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1183" stop-color="#0095D5" />
          <stop offset="0.4178" stop-color="#3C83DC" />
          <stop offset="0.6962" stop-color="#6D74E1" />
          <stop offset="0.8333" stop-color="#806EE3" />
        </linearGradient>
        <linearGradient
          id={`${hash}paint2`}
          x1="-4.71694"
          y1="26.5496"
          x2="21.3385"
          y2="0.494103"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1075" stop-color="#C757BC" />
          <stop offset="0.2138" stop-color="#D0609A" />
          <stop offset="0.4254" stop-color="#E1725C" />
          <stop offset="0.6048" stop-color="#EE7E2F" />
          <stop offset="0.743" stop-color="#F58613" />
          <stop offset="0.8232" stop-color="#F88909" />
        </linearGradient>
        <clipPath id="clip0_823_415">
          <rect width="28" height="28" fill="white" transform="translate(0 0.809326)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconKotlin;
