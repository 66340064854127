import React from 'react';

const IconCsharp = ({ ...styles }) => (
  <svg viewBox="0 0 64 64" version="1.1" {...styles}>
    <g id="logos/c-copy-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fill="#D2C5F2" x="0" y="0" width="64" height="64"></rect>
      <path
        d="M25.5825545,45 C30.6199377,45 34.0809969,42.0841121 36,38.682243 L31.2367601,36.2176235 C30.4143302,37.6408545 29.1464174,39.8624833 25.9937695,39.8624833 C22.7383178,39.8624833 20.0311526,37.1895861 20.0311526,31.8090788 C20.0311526,27.7129506 22.0872274,24.0333778 25.9937695,24.0333778 C28.4610592,24.0333778 30.6199377,25.9425901 31.1339564,27.7823765 L35.9314642,25.35247 C34.2180685,21.6728972 30.1744548,19 25.7196262,19 C18.8660436,19 14,24.7276368 14,31.9826435 C14,40.2790387 19.1744548,45 25.5825545,45 Z"
        id="C"
        fill="#0B123B"
        fillRule="nonzero"
      ></path>
      <g id="#-3" transform="translate(38.000000, 25.000000)" fill="#0B123B" fillRule="nonzero">
        <path
          d="M4.34079174,16 L5.01204819,11.1501416 L7.22719449,11.1501416 L6.53356282,16 L8.79345955,16 L9.48709122,11.1501416 L12.5077453,11.1501416 L12.5077453,8.88385269 L9.7555938,8.88385269 L10.0688468,6.75354108 L13,6.75354108 L13,4.48725212 L10.3597246,4.48725212 L10.9638554,0 L8.70395869,0 L8.09982788,4.48725212 L5.9070568,4.48725212 L6.53356282,0 L4.20654045,0 L3.60240964,4.48725212 L0.492254733,4.48725212 L0.492254733,6.75354108 L3.31153184,6.75354108 L3.02065404,8.88385269 L0,8.88385269 L0,11.1501416 L2.72977625,11.1501416 L2.05851979,16 L4.34079174,16 Z M7.51807229,8.88385269 L5.28055077,8.88385269 L5.59380379,6.75354108 L7.80895009,6.75354108 L7.51807229,8.88385269 Z"
          id="#"
        ></path>
      </g>
    </g>
  </svg>
);

export default IconCsharp;
